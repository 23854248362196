import React from "react";
import ReactDOM from "react-dom";
import {Tracker} from "../components/shared/MixpanelPageTracker";

function TrackerApp() {
  return (
    <Tracker/>
  );
}

ReactDOM.render(TrackerApp(), document.getElementById("page_tracker"));
