import {Mixpanel} from "../../tracking/Mixpanel";
import {useEffect} from "react";

export const Tracker = () => {
  const actions = {
    trackPage: () => Mixpanel.track(Mixpanel.PAGE_VIEW, {page_url: window.location.href}),
    trackStartProjectApply: (() => {
      const button = document.querySelector(".btn-login-apply");
      if (button) {
        button.addEventListener("click", () => {
          Mixpanel.track(Mixpanel.PROJECT_APPLY_START, {project_type: context.projectType} );
        });
      }
    }),
  };

  useEffect(() => {
    actions.trackPage();
    actions.trackStartProjectApply();
  });
  return null;
};
